<template>
  <div>
    <div v-if="storefronts && storefronts.length" class="position-table">
      <md-table v-model="storefronts">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ disabled: selectedId && selectedId !== item.id }"
          @click="selectedId = !selectedId ? item.id : null"
        >
          <md-table-cell>
            <md-checkbox
              :value="selectedId !== item.id"
              :disabled="selectedId && selectedId !== item.id"
              @change="selectedId = !selectedId ? item.id : null"
            />
          </md-table-cell>

          <md-table-cell md-label="ID" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Название" class="custom-field">
            <span :title="item.title">{{ item.title }}</span>
          </md-table-cell>

          <md-table-cell md-label="Иконка" class="custom-field">
            <img class="cover-image" :src="item.cover" alt="Icon" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedId: null,
    };
  },

  computed: {
    ...mapState({
      storefronts: (state) => state.storefronts.storefronts,
    }),
  },

  watch: {
    selectedId(value) {
      this.$emit("select", value);
    },
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getStorefronts();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("storefronts", ["getStorefronts"]),
  },
};
</script>

<style lang="scss" scoped>
.custom-field {
  max-width: 150px;

  &::v-deep {
    .md-table-cell-container {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.cover-image {
  max-width: 100%;
  max-height: 100px;
  object-fit: cover;
}

::v-deep {
  .md-table-row {
    cursor: pointer;

    &.disabled {
      cursor: auto;
      opacity: 0.5;
    }
  }
}
</style>
